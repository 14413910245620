import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import './Map.css';

mapboxgl.accessToken = 'pk.eyJ1IjoibGlldXh1bmlxdWVzLWtvbnN0aSIsImEiOiJjbGFiMnpqNTIwMXkyM29ueHhiMHFkbm1nIn0.BS8FRz4yNBeeazPmbgEGYw';


export default function Map(props) {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const marker = useRef(null);
    const [lng] = useState(props.long);
    const [lat] = useState(props.lat);
    const [zoom] = useState(9);

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/dark-v10',
            center: [lng, lat],
            zoom: zoom,
            dragPan: false
        });

        marker.current = new mapboxgl.Marker({ color: 'white' })
            .setLngLat([props.long, props.lat])
            .addTo(map.current);
    });

    return (
        <div className="unique-box">
            <div ref={mapContainer} className="map-container" />
        </div>
    );
}

import React from 'react';
import "./Header.css";

export default function Header(props) {

    return (
        <div className="unique-header">
            <p className="unique-title">LIEUX UNIQUES</p>
        </div>
    );
}

import Header from "../../components/Header";

export default function HomePage() {

    return (<div className="TagPage">
        <Header/>
        <div className="unique-center">
            <p>Scan a shirt to begin</p>
        </div>
    </div>);

}

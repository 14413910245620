import {useEffect, useState} from "react";
import Map from "../../components/Map";
import Header from "../../components/Header";
import VoucherBox from "../../components/VoucherBox";
import InfoBox from "../../components/InfoBox";
import Loading from "../../components/Loading";
import {convertLatLngToUniques} from "../../utils/coordinatesUtils";

import './TagPage.css';
import {useParams} from "react-router-dom";

export default function TagPage() {
    const props = useParams();
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState({
        tagId: "", orderId: "", item: {
            number: 0, type: "", color: ""
        }, place: {
            lat: 38.868, long: 1.1965
        }, user: {
            displayName: "", isRegistered: false, userId: null
        }, isGift: false
    });

    useEffect(() => {
        async function fetchData() {
            const result = await fetch('https://my.lieux-uniques.com/api/tag/' + props.tagId);
            const data = await result.json();

            setData(data);
            setLoading(false);
        }

        fetchData();
    }, [props.tagId]);

    if (loading) {
        return (<div className="TagPage">
            <Header/>
            <div className="unique-center">
                <Loading/>
            </div>
        </div>);
    }

    return (<div className="TagPage">
        <Header/>
        <div className="unique-center">
            <InfoBox name={data.user.displayName.split(" ")[0]}
                     unique={convertLatLngToUniques(data.place.lat, data.place.long, 4)}/>
            <Map lat={data.place.lat} long={data.place.long}/>
            <VoucherBox/>
        </div>
    </div>);
}

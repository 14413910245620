function convertLatLngToUniques(lat, lng, precision) {
    const latNumber = Number(lat).toFixed(precision);
    const lngNumber = Number(lng).toFixed(precision);

    const latString = (lat > 0) ? latNumber + "°N" : (latNumber * -1) + "°S";
    const lngString = (lng > 0) ? lngNumber + "°E" : (lngNumber * -1) + "°W";

    return latString + " " + lngString
}

export {
    convertLatLngToUniques
};

import './InfoBox.css';

export default function InfoBox(props) {
    return (
        <div className="unique-box unique-info-box">
            {/*<p className="unique-name">Hello {props.name}</p>*/}
            <p className="unique-subline">Welcome to your unique place!</p>
            <p className="unique-coordinates">{props.unique}</p>
        </div>
    );
}

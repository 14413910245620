import React from 'react';
import './VoucherBox.css';

export default function VoucherBox(props) {
    return (
        <div className="unique-box unique-voucher-box">
            <p className="unique-title"><b>Thank you for your trust in our Brand!</b></p>
            <p className="unique-title">10€ Voucher for you and your Friends!</p>
            <input className="unique-voucher-input" value="uniquefriends" contentEditable="false"
                   onClick={() => navigator.clipboard.writeText("uniquefriends")}/>
        </div>
    );
}
